.background { 
  background: url(./images/2.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width:100vw;
  height:100vh;
  animation: changeBg 30s infinite ease-in-out;
}

@keyframes changeBg{
  0%,10%  {background-image: url(./images/2.jpg);}
  20%, 30%  {background-image: url(./images/3.jpg);}
  40%, 50%  {background-image: url(./images/4.jpg);}
  60%, 70%  {background-image: url(./images/5.jpg);}
  80%, 90%  {background-image: url(./images/1.jpg);}
}

